import * as React from "react";
import Layout from "../components/Layout/Layout";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { VCenter } from "../styles/globalStyles";

const pageInfo = {
  title: "Sorry, we couldn't find this page",
  path: "/404",
};

const SectionWrap = styled.section`
  min-height: calc(85vh - 80px);
  position: relative;
  padding: 60px 0;
  overflow: hidden;
  z-index: 1;
  ${VCenter}

  @media (max-width: 900px) {
    min-height: calc(60vh - 80px);
  }

  @media (max-width: 500px) {
    min-height: calc(100vh - 80px);
  }

  .row {
    @media (max-width: 767px) {
      align-items: flex-start !important;
    }
  }

  &::before {
    content: "";
    position: absolute;
    right: -162px;
    top: 100px;
    height: 843px;
    width: 884px;
    background-image: url(https://d3u02jzdlk4bfv.cloudfront.net/assets/ui/background-shape-546df52c20fe8c72859e04efd63a864409f4a4dbe3e9d856cd2ec8cc31c0b5b1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: -1;

    @media (max-width: 1200px) {
      right: -362px;
      top: 100px;
    }

    @media (max-width: 900px) {
      right: -299px;
      top: 90px;
      height: 653px;
      width: 664px;
    }

    @media (max-width: 768px) {
      right: -279px;
      top: 165px;
    }

    @media (max-width: 420px) {
      right: -339px;
      top: 358px;
    }
  }
`;

const ContentSection = styled.div`
  padding: 60px 0;

  @media (max-width: 767px) {
    padding-top: 0;
  }
`;

const StyledH1 = styled.h1`
  color: #333;
  font-size: 42px;
  font-family: "ProximaNova-Bold";
  line-height: 52px;
  font-weight: bold;
  margin-bottom: 30px;

  @media (max-width: 900px) {
    font-size: 36px;
    line-height: 42px;
    width: 92%;
  }
`;

const StyledH4 = styled.h4`
  color: #1e303f;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 30px;
`;

const StyledPara = styled.p`
  color: #333;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  font-family: "ProximaNova-Regular";

  a {
    font-weight: bold;
    color: #065ee5;
    text-decoration: underline;
  }
`;

const ImageSction = styled.div`
  @media (min-width: 1600px) {
    text-align: right;
  }

  img {
    width: 300px;

    @media (min-width: 1400px) {
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <SectionWrap>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <ContentSection>
                <StyledH1>Looks like we couldn’t find that page.</StyledH1>
                <StyledH4>
                  But this doesn’t have to be the end of the road.
                </StyledH4>
                <StyledPara>
                  You can try a fresh start on the <Link to="/">homepage</Link>,
                  or find answers and{" "}
                </StyledPara>
                <StyledPara>
                  contact information in the{" "}
                  <a href="https://help.charitableimpact.com">Help Centre</a>.{" "}
                  We’re here to help.
                </StyledPara>
              </ContentSection>
            </Col>
            <Col md={6}>
              <ImageSction>
                <img
                  alt="Errorpage"
                  src="https://d3u02jzdlk4bfv.cloudfront.net/assets/ui/errorpage-dbc12c19b253726331e395a7d8d76a020634e4aa4f6f00ee859e995f2b805ccc.png"
                  loading="lazy"
                />
              </ImageSction>
            </Col>
          </Row>
        </Container>
      </SectionWrap>
    </Layout>
  );
};

export default NotFoundPage;
